html, #root, body {
    @apply bg-white h-full font-['Helvetica'];
}

.device-list-container .accordion .close:not(:last-child) {
    border-bottom: 0;
}

.drawer-menu .menu-item:not(:last-child) {
    border-bottom: 0;
}

.menu .active .menu-item {
    @apply bg-white rounded-sm text-blue-950;
}

.menu .menu-item {
    @apply text-white h-10 flex items-center justify-center p-2;
}

table td, table th {
    @apply py-3 px-1 border border-neutral-200 text-center;
}

.table-listing {
    max-height: calc(100%);
    overflow-y: auto;
}

.toggle {
    --width: 80px;
    --height: 30px;

    position: relative;
    display: inline-block;
    width: var(--width);
    height: var(--height);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    border-radius: var(--height);
    cursor: pointer;
}

.toggle input {
    display: none;
}

.toggle .slider {
    @apply bg-red-800;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: var(--height);
    transition: all 0.4s ease-in-out;
}

.toggle .slider::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: calc(var(--height));
    height: calc(var(--height));
    border-radius: calc(var(--height) / 2);
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    transition: all 0.4s ease-in-out;
}

.toggle input:checked + .slider {
    @apply bg-green-800;
}

.toggle input:checked + .slider::before {
    transform: translateX(calc(var(--width) - var(--height)));
}

.toggle .labels {
    position: absolute;
    top: 6px;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 14px;
    transition: all 0.4s ease-in-out;
}

.toggle .labels::after {
    @apply text-white;
    content: attr(data-off);
    position: absolute;
    right: 20px;
    opacity: 1;
    transition: all 0.4s ease-in-out;
}

.toggle .labels::before {
    @apply text-white;
    content: attr(data-on);
    position: absolute;
    left: 20px;
    opacity: 0;
    transition: all 0.4s ease-in-out;
}

.toggle input:checked ~ .labels::after {
    opacity: 0;
}

.toggle input:checked ~ .labels::before {
    opacity: 1;
}

.react-confirm-alert-overlay {
    @apply bg-black bg-opacity-40;
    z-index: 105;
}

.calendar {
    @apply w-full h-auto;
}

.calendar .days div:hover {
    @apply border-blue-950;
}

.calendar .from, .calendar .to {
    @apply bg-blue-950;
    background: #001C6F !important;
}

.calendar .light, .calendar .lights {
    background-color: rgba(0, 28, 111, 0.24);
    color: #001C6F;
}

.calendar .days {
    @apply p-0;
}

/*loader*/
.loader-container {
    background: url("https://firebasestorage.googleapis.com/v0/b/trackandgo-4a78a.appspot.com/o/favicon.ico?alt=media") no-repeat center;
}

.loader {
    width: 72px;
    height: 72px;
    border: 2px solid #2a2a2a;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

.loader::after,
.loader::before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    background: #Fa2121;
    width: 6px;
    height: 6px;
    border-radius: 50%;
}

.loader::before {
    left: auto;
    top: auto;
    right: 0;
    bottom: 0;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* Drawer menu */
#drawer-menu.closed {
    transform: translateX(-100%);
    transition: transform 0.2s ease-in-out;
}

#drawer-menu.opened {
    transform: translateX(0);
    transition: transform 0.2s ease-in-out;
}

/* Popup animation */
.c-popup {
    animation: fadeIn 0.5s;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/**/
.css-13cymwt-control{
    border: 1px solid #E2E2E2 !important;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
}
.css-1fdsijx-ValueContainer {
    padding: 10px !important;
}
